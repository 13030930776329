import { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../libs/firebase";

function Component() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  return (
    <div className="sm:flex items-center justify-center h-screen sm:w-screen w-full bg-secondary">
      <div>
        <div className="max-w-lg p-8 bg-primary sm:rounded-sm sm:shadow-md sm:w-auto w-ふっl sm:h-auto h-screen mx-auto">
          <h1 className="text-h2 mb-5 body w-auto">Login Form (for admin)</h1>
          <label
            htmlFor="email"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Email
          </label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            id="email"
            className="mb-2 outline-none outline-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-momo focus:border-momo block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-momo dark:focus:border-momo"
          />
          <label
            htmlFor="password"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Password
          </label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            id="password"
            className="mb-2 outline-none outline-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-momo focus:border-momo block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-momo dark:focus:border-momo"
          />
          {/* {auth.currentUser.displayName} */}
          <button
            className="text-white bg-momo hover:bg-momo focus:ring-4 focus:outline-none outline-4 focus:ring-momo font-medium rounded-sm text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-momo dark:hover:bg-momo dark:focus:ring-momo"
            onClick={() => {
              signInWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                  // Signed in
                  const user = userCredential.user;
                  window.location.href = "/post";
                  // ...
                })
                .catch((error) => {
                  const errorCode = error.code;
                  const errorMessage = error.message;
                  setErrorMessage(errorMessage);
                });
            }}
          >
            Submit
          </button>
          {errorMessage ? (
            <p className="text-red-500 text-sm mt-2">{errorMessage}</p>
          ) : (
            <></>
          )}
        </div>
        <a
          href="https://firebase.google.com/?hl=ja"
          className="absolute bottom-3 right-0 left-0 m-auto w-32"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="/Built_with_Firebase_Logo_Light.svg"
            alt="build with firebase"
            className="dark:hidden"
            loading="lazy"
          ></img>
          <img
            src="/Built_with_Firebase_Logo_Dark.svg"
            alt="build with firebase"
            className="hidden dark:block"
            loading="lazy"
          ></img>
        </a>
      </div>
    </div>
  );
}

export default Component;
